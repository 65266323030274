<template>
  <base-modal
    label="abrir modal"
    @confirm="saveForm"
    @cancel="resetForm"
  >
    <form>
      <base-input
        v-model="form.teste"
        label="teste"
        type="text"
      />
    </form>
  </base-modal>
</template>

<script lang="ts">
import { defineComponent, ref, toRaw, unref } from 'vue'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseModal from '@/components/base/BaseModal.vue'

export default defineComponent({
  components: {
    BaseModal,
    BaseInput,
  },
  setup () {
    const form = ref({
      teste: ''
    })

    const formBuffer = ref({ ...form.value })

    const resetForm = () => {
      form.value = { ...formBuffer.value }
    }

    const saveForm = () => {
      formBuffer.value = { ...form.value }

      console.log('save form', toRaw(unref(form)))
    }

    return {
      form,
      saveForm,
      resetForm
    }
  }
})
</script>
