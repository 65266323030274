<template>
  <div>
    <button-modal/>
  </div>
  <div><br/></div>
  <div>
    <custom-modal/>
  </div>
  <div><br/></div>
  <div>
    <user-tags-modal/>
  </div>

  <h2 class="py-8">
    Idioma
  </h2>
  <language-select />

  <p class="my-2">Exemplos para <i>message.hello</i>:</p>
  <ul>
    <li><b>No template:</b> {{ $t("message.hello") }}</li>
    <li><b>No script:</b> {{ helloWord }}</li>
  </ul>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import ButtonModal from '@/components/example/ButtonModal.vue'
import CustomModal from '@/components/example/CustomModal.vue'
import UserTagsModal from '@/components/tags/UserTagsModal.vue'
import LanguageSelect from '@/components/settings/languageSelect.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    ButtonModal,
    CustomModal,
    UserTagsModal,
    LanguageSelect,
  },

  setup() {
    const { t } = useI18n()

    const helloWord = computed(() => {
      return t('message.hello')
    })
    
    return {
      helloWord
    }
  }
})
</script>
