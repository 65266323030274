
import { defineComponent } from 'vue'
import BaseModal from '@/components/base/BaseModal.vue'
import { useToggle } from '@/composables/useToggle'

export default defineComponent({
  components: {
    BaseModal
  },
  setup () {
    const {
      isActive: open,
      setInactive: close
    } = useToggle(false)

    return {
      open,
      close,
    }
  }
})
