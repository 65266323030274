
import { computed, defineComponent } from 'vue'
import ButtonModal from '@/components/example/ButtonModal.vue'
import CustomModal from '@/components/example/CustomModal.vue'
import UserTagsModal from '@/components/tags/UserTagsModal.vue'
import LanguageSelect from '@/components/settings/languageSelect.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    ButtonModal,
    CustomModal,
    UserTagsModal,
    LanguageSelect,
  },

  setup() {
    const { t } = useI18n()

    const helloWord = computed(() => {
      return t('message.hello')
    })
    
    return {
      helloWord
    }
  }
})
