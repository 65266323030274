<template>
  <base-modal v-model:open="open" hide-footer>
    <template v-slot:button>
      <div class="bg-blue-500 w-1/2">
        abrir modal
      </div>
    </template>

    <button @click.prevent="close">ação personalizada</button>
  </base-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BaseModal from '@/components/base/BaseModal.vue'
import { useToggle } from '@/composables/useToggle'

export default defineComponent({
  components: {
    BaseModal
  },
  setup () {
    const {
      isActive: open,
      setInactive: close
    } = useToggle(false)

    return {
      open,
      close,
    }
  }
})
</script>
